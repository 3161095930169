div.toast {
    position: fixed;
    margin: auto;
    display: none;
    left: 50%;
    transform: translateX(-50%);
    top: -300px;
    opacity: 0;
    width: 100%;
    max-width: 500px;
    padding: 21px 16px;
    color: #fff;
    border: 1px solid #611a15;
    background-color: #d44141;
    font-size: 1.25rem;
    text-align: center;
    border-radius: 16px;
    transition: opacity 1s ease-in-out;
    box-shadow: inset 0 0 3px 0 #611a15;
    z-index: 100000;

    &.open {
        opacity: 1;
        display: block;
        animation: toast 5s ease-in-out forwards;
    }

    @keyframes toast {
        0% {
            transform: translateX(-50%);
            top: -300px;
        }
        20% {
            transform: translateX(-50%);
            top: 100px;
        }
        80% {
            opacity: 1;
            transform: translateX(-50%);
            top: 100px;
        }
        100% {
            opacity: 0;
            transform: translateX(-50%);
            top: -300px;
        }
    }
}

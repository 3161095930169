@import "../../variables";
@import "../../styles";

header.header {
    align-items: center;
    background: #06132f;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    box-shadow: none;
    color: $dark-blue;
    display: flex;
    height: 80px;
    justify-content: center;
    overflow: hidden;
    padding: 1em;
    position: fixed;
    width: 100%;
    z-index: 1000000;
}

.containerMiaLogo {
    display: flex;
    justify-content: center;
    width: min-content;
}

.newLogoMia {
    height: 35px;
}

.isologo {
    height: 50px;
    position: absolute;
    right: -50px;
    top: 0;
    width: 350px;

    @include mob-large() {
        width: 150px;
    }
}
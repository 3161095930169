@use "../../variables" as v;

.button {
    border-radius: 100px;
    text-align: center;
    font-size: 1.25em;
    line-height: 1.20;
    font-weight: 600;
    padding: 18px;
    border: 2px solid;
    cursor: pointer;
    min-width: 238px;
    margin-bottom: 20px;
    transition: all 0.2s ease-in-out;
    a {
        font-weight: 600;
        color: v.$white;
        display: block;
        margin: -18px;
        padding: 18px;
    }

    &.primary {
        color: v.$white;

        &.blue {
            background-color: v.$blue;
            &:hover {
                background-color: v.$blue80;
            }
        }
        &.dark-blue {
            background-color: v.$dark-blue;
            &:hover {
                background-color: v.$dark-blue80;
            }
        }
        &.medium-blue {
            background-color: v.$medium-blue;
            &:hover {
                background-color: v.$medium-blue80;
            }
        }
        &.orange {
            background-color: v.$orange;
            &:hover {
                background-color: v.$orange80;
            }
        }
        &.orange-strong {
            background-color: v.$orangeStrong;
            &:hover {
                background-color: v.$orangeStrong80;
            }
        }
        &.gray {
            background-color: v.$gray;
            &:hover {
                background-color: v.$gray80;
            }
        }
        &.black {
            background-color: #000;
            &:hover {
                background-color: #333;
            }
        }
    }

    &.secondary {
        background-color: v.$white;
        &.blue {
            border-color: v.$blue;
            color: v.$blue;
            a {
                color: v.$blue;
            }
            &:hover {
                background-color: v.$blue20;
            }
        }
        &.dark-blue {
            border-color: v.$dark-blue;
            color: v.$dark-blue;
            a {
                color: v.$dark-blue;
            }
            &:hover {
                background-color: v.$dark-blue20;
            }
        }
        &.medium-blue {
            border-color: v.$medium-blue;
            color: v.$medium-blue;
            a {
                color: v.$medium-blue;
            }
            &:hover {
                background-color: v.$medium-blue20;
            }
        }
        &.orange {
            border-color: v.$orange;
            color: v.$orange;
            a {
                color: v.$orange;
            }
            &:hover {
                background-color: v.$orange20;
            }
        }
        &.gray {
            border-color: v.$gray;
            color: v.$gray;
            a {
                color: v.$gray;
            }
            &:hover {
                background-color: v.$gray20;
            }
        }
        &.black {
            border-color: #000;
            color: #000;
            a {
                color: #000;
            }
            &:hover {
                background-color: #ccc;
            }
        }
    }

    &:disabled {
        background-color: #bbb!important;
        color: #666;
        border: none!important;
    }
}

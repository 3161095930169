@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

@import "./variables";
@import "./styles";

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: "Lato", sans-serif;
    line-height: 1;
    text-decoration: none;
    list-style-type: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Montserrat", sans-serif;
}

body {
    max-width: 100%;
    min-height: 100vh;
    overflow-x: hidden;
}

main {
    max-width: 100%;
    min-height: 100vh;

    @media all and (max-width: 1600px) {
        font-size: 14px;
    }

    @media all and (max-width: 1200px) {
        font-size: 12px;
    }
}

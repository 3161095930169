@import "../../styles";

main.main {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .cardContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        @include mob-large() {
            display: flex;
            justify-content: center;
            width: 100%;
        }
    }
    .containerMIALogo {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    img.mia {
        @include mob-large() {
            width: 250px;
        }
    }

    img.leftCloud {
        position: fixed;
        top: 3vh;
        left: -25vw;
        width: 50%;
        min-width: 600px;
        max-width: 1000px;
        height: 97vh;
        @include mob-large() {
            top: 70vh;
            left: -80vw;
        }
    }

    img.rightCloud {
        position: fixed;
        top: -30vh;
        right: -18vw;
        width: 50%;
        min-width: 600px;
        max-width: 1000px;
        @include mob-large() {
            top: 15;
            right: -120vw;
        }
    }

    video.image {
        position: fixed;
        top: -80px;
        right: -140px;
        border: 1px solid #fff;
        width: 760px;
        height: 760px;

        &.connection {
            top: -99px;
            right: -99px;
            border-radius: 0;
            border-top-left-radius: 50%;
            border-bottom-left-radius: 100%;
            box-shadow: inset 10px 10px 50px 50px #fff;
            @media all and (max-width: 1600px) {
                width: 500px;
                height: 470px;
                top: -40px;
                right: -40px;
            }
            @media all and (max-width: 1200px) {
                width: 400px;
                height: 380px;
                top: -20px;
            }
        }

        @media all and (max-width: 1600px) {
            width: 600px;
            height: 570px;
            top: -60px;
            right: -80px;
        }
        @media all and (max-width: 1400px) {
            width: 530px;
            height: 500px;
            top: -60px;
            right: -100px;
        }
        @media all and (max-width: 1280px) {
            width: 300px;
            height: 440px;
            top: -60px;
            right: -100px;
        }
        @include mob-large() {
            display: none;
        }
    }
}

.webex-image {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
}

.white {
    width: 60px;
    height: 35px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: #fff;
}

.white2 {
    width: 36%;
    height: 8%;
    position: absolute;
    top: 41%;
    left: 54%;
    z-index: 100;
    background-color: #fff;
}

.gray {
    background-color: #E0E1E0;
    width: 7%;
    height: 3%;
    position: absolute;
    top: 53%;
    left: 65.4%;
    z-index: 100;
}
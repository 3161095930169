#email {
    .card {
        margin-top: 4em;
    }
    > div {
        flex-direction: column;
        justify-content: space-between;
        min-height: 100vh;
        padding: 1em;
    }
    h1 {
        margin-bottom: 0.25em;
    }
    p {
        margin-bottom: 2em;
    }
    img.webex {
        width: 100px;
    }
    button {
        padding: 8px 16px;
        margin: 0;
        margin-bottom: 2em;
        min-width: 260px;
    }
    input.input {
        border-radius: 8px;
        min-width: 260px;
        padding: 8px;
        border: none;
        outline: none;
        margin-bottom: 16px;
        color: #000;
        font-size: 18px;
        border: 1px solid #000;
        &::placeholder {
            color: #333;
        }
    }
    img.button-mia {
        max-width: 198px;
    }
}
#home {
    > div {
        flex-direction: column;
        justify-content: space-between;
        min-height: 100vh;
        padding: 1em;
    }
    img.webex {
        width: 100px;
    }
    button {
        padding: 8px 16px;
        margin: 0;
        margin-bottom: 1em;
        min-width: 260px;
        a {
            margin: -16px;
        }
    }
    .register {
        font-weight: 600;
        color: #38459dcc;
        font-size: 1.5em;
        cursor: pointer;
    }
    footer.footer {
        align-self: flex-end;
        display: flex;
        align-items: center;
        font-size: 14px;
        width: 100%;
        max-width: 800px;
        text-align: center;
        margin: 0 auto;
    }
}
#loading {
    .card {
        margin-top: 4em;
    }
    > div {
        flex-direction: column;
        justify-content: space-between;
        min-height: 100vh;
        padding: 1em;
        }
    img.webex {
        width: 100px;
    }
    img.spinner {
        animation: spin 1s linear infinite forwards;
        max-width: 100px;
        margin-top: 2.5em;
    }
    @keyframes spin {
        0% {
            transform: rotate(0);
        }
        100% {
            transform: rotateZ(359deg);
        }
    }
}
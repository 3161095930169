$white: #fff;
$blue: #171b37;
$blue80: #38459dcc;
$blue60: #38459d99;
$blue40: #38459d66;
$blue20: #38459d33;

$gray: #c2b7ac;
$gray80: #c2b7accc;
$gray60: #c2b7ac99;
$gray40: #c2b7ac66;
$gray20: #c2b7ac33;

$orange: #ff9c57;
$orange80: #ff9c57cc;
$orange60: #ff9c5799;
$orange40: #ff9c5766;
$orange20: #ff9c5733;
$orangeStrong: #ff6d00;
$orangeStrong80: #bf5200;

$dark-blue: #0d2237;
$dark-blue80: #0d2237cc;
$dark-blue60: #0d223799;
$dark-blue40: #0d223766;
$dark-blue20: #0d223733;

$medium-blue: #294c74;
$medium-blue80: #294c74cc;
$medium-blue60: #294c7499;
$medium-blue40: #294c7466;
$medium-blue20: #294c7433;

$brakpoints: (
    sm: 540px,
    md: 768,
    lg: 1024,
);

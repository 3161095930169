@use "../../variables" as v;
@import "../../styles";
.card {
    margin: 8em 0 2em;
    padding: 2em 1em;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: transparent;
    z-index: 1000;
    width: 710px;
    @include mob-large() {
        margin-top: 1em;
    }

    input,
    select {
        color: #777;

        &::placeholder {
            color: #e8e1db;
            opacity: 1;
        }
    }

    header {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 115px;
        width: 105px;
        position: relative;
        margin-bottom: 2.5em;

        span {
            font-size: 1.875em;
            font-weight: bold;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    > img {
        margin-bottom: 2.5em;
    }

    h6 {
        font-size: 1em;
        font-weight: 500;
        margin-bottom: 1em;
    }

    h4 {
        color: v.$blue;
        font-size: 2em;
        margin-bottom: 0.5em;
    }

    h2 {
        font-size: 2.5em;
        padding-bottom: 0.6em;
        margin-bottom: 0.875em;
        width: 100%;
        border-bottom: 8px solid v.$orangeStrong;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        color: v.$blue;

        img {
            margin-right: 0.4em;
        }
    }

    p {
        font-size: 2em;
        margin-bottom: 1em;
        line-height: 1.2;
        color: v.$dark-blue;
        text-align: center;
        &.blue {
            color: #123787;
        }
        &.disclaimer {
            font-size: 1.5em;
        }
    }

    button {
        margin-top: 1em;
        width: 200px;
    }
    @include mob-large() {
        p {
            font-size: 1.2em;
        }
    }

    .qr {
        max-width: 200px;
        color: #123787;
        margin-top: -26px;
        margin-bottom: 2em;
    }
}
